// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.trading-info-card{
  background-color: #fff;
  border-radius: 15px;
 .header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:20px 20px 0px 20px;
  h1{
    color: #1D1E25;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .status-ongoing, 
  .status-failed {
    padding: 5px 15px;
    border-radius: 30px;
    background: rgba(222, 12, 68, 0.10);

    svg {
      margin-right: 5px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 30px;
      background-color: $primary-color;
      margin-right: 8px;
    }
  }

  .status-achieved {
    padding: 5px 15px;
    border-radius: 30px;
    background: rgba(12, 222, 65, 0.1);

    svg {
      margin-right: 5px;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 30px;
      background-color: $primary-color;
      margin-right: 8px;
    }
  }

 }
 .ant-divider{
  margin-block: 1rem;
 }
 .inner{
  padding: 20px;
  .inner-container{
    padding: 17px 20px;
    border-radius: 10px;
    background: #F6F6F6;
    margin-bottom: 27px;
    .value{
      color: #1D1E25;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 5px;
    }
    .description{
      color: #717184;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
    .loss{
      color: #DE0C44;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &:last-child{
      margin-bottom: 0px;
    }
   }

 }
 
}
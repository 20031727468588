// index.scss

@import '../../../styles/variables.scss';

// Custom styles for the header
.certificate-card {
    background: url('../../../../public/images/certificate.png') center/cover no-repeat;
    min-height: 240px;
    max-height: 240px;
    height: 240px;
    margin-bottom: 20px;
    border-radius: 15px;

    .certificate-internal {
        border-radius: 15px;
        //background: rgba(6, 7, 9, 0.75);
        //backdrop-filter: blur(3.5px);
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;

        .box {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .icon {
                width: 60px;
                height: 60px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            h1 {
                color: #FFF;
                text-align: center;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }
        }
    }

}
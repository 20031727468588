// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.payout-card{
  border-radius: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  .cardcontainer{
    .header{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      padding-bottom: 0px;
      margin-bottom: 0px;
      h6{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
     
    }
    .ant-divider{
      margin: 18px 0px;
    }
   
  }
  .custom-row{
    padding: 20px;
      padding-top: 0px;

    .payoutCard{
      border-radius: 10px;
      background: #F6F6F6;
      padding: 17px 20px;
      .header{
        margin-bottom: 30px;
        padding: 0px;
        .imageGroup{
  
          svg:nth-child(1){
            margin-left: -10px;
            z-index: 2;
            position: relative;
          }
          svg:nth-child(2){
            margin-left: -10px;
            z-index: -1;
          }
        }
      }
      h6{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 5px;

      }
      p{

        color: #1D1E25;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 166.667% */
      }
      &.active{
        background: url('../../../../public/images/payoutcard.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */

        .tooltip{
          path{
            fill: #fff;
          }
        }
        h6,p{
          color: #fff;
        }
      }
    }
  
  }

 


}
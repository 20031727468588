// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.icon-card{
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
  flex: 1 0 0%;
  .icopnbox{
      width: 60px;
      min-width: 60px;
      height: 60px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
      margin-bottom: 15px;
  }
  .heading{
    color: #717184;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    margin-bottom:10px
  }
  .value{
    color: #1D1E25;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .time{
    .startTime{
      color: #717184;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 10px;
      span{
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
}
.verticleIcon{
  display: flex;
  align-items: center;
  gap: 20px;
  .icopnbox{
    margin-bottom: 0px;
  }
  .value{
    color: #1D1E25;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }
}
// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header
.accordianCard{
  padding: 20px;
  border-radius: 15px;
  background-color: #fff;
  // margin-bottom: 20px;
  .ant-collapse{
    border: none;
    .ant-collapse-item{
      border: none;
      .ant-collapse-header{
        padding: 0;
        background-color: #fff;
        color: #1D1E25;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        align-items: center;
      }
      &.ant-collapse-item-active{
        .ant-collapse-header{
          padding: 0;
          background-color: #fff;
          color: $primary-color;
        }
        .ant-collapse-content{
          border: none;
        }
        .ant-collapse-content-box{
          padding: 0px;
          padding-top: 13px;
          .content{
            color: #717184;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
          }
        }

      }
    }
  }


}
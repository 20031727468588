// index.scss

@import '../../styles/_variables.scss'; 
// Custom styles for the header
.PageContent{
  margin-left: 288px;
  width: calc(100% - 288px);
  // min-height: 100vh;
  background-color: #F6F6F6;
  // padding-top: 100px;
  
}
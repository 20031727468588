// index.scss

@import '../../../styles/variables.scss';
// Custom styles for the header

// Countdown.scss
.countdownCard{
  background: url('../../../../public/images/countdownbg.png') center/cover no-repeat; /* Replace 'path-to-your-image.jpg' */
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    justify-content: flex-start !important;
    
    border-radius: 15px 15px 0px 0px;
    background: #060709;
    padding: 18px 28px ;
    gap: 15px;
    .clock{
      width: 60px;
      min-width: 60px;
      height: 60px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(91deg, #DD0744 0.7%, #FF9A44 143.6%);
    }
    h5{
      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: 33px;
    }

  }
  .countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
  
    .countdown-box {
      width: 80px;
      height: 74px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 10px;
background: rgba(255, 255, 255, 0.10);
    }
  }
  .gmt{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin-block: 1rem;

  } 
 
}



@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
